export const firebaseConfig = {
    "projectId": "guidde-production-eu",
    "appId": "1:479143857377:web:0c790f4493f23549487ba0",
    "databaseURL": "https://guidde-production-eu-default-rtdb.europe-west1.firebasedatabase.app",
    "storageBucket": "guidde-production-eu.appspot.com",
    "locationId": "europe-west",
    "apiKey": "AIzaSyC_n-2owO4Ic6C7BJW8PM5q-BsGZWysyhI",
    "authDomain": "eu.guidde.com",
    "messagingSenderId": "479143857377",
    "environment": "production"
}

export const STIGG_CLIENT_KEY =
'client-beb97e25-643c-43e0-b743-fe6068e09717:0bd8f132-b309-46b2-b611-146a5c7a2d8f'
export const entailApiKey =
'et-WJgibNJi14XN2kj8esMTq2ke_JhVpI8n5kq0lPv1-2eEnbIv'
export const tapfiliateAccountId =
'38971-9bf427'